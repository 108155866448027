import React, { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import { Card, Grid } from '@mui/material';
import MDTypography from 'components/MDTypography';

import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here

import pb from 'lib/pocketbase';
import DiseaseAnalysisPieChart from './charts/diseaseAnalysisPieChart';
import StackedAreaChart from './charts/analysisByCountry';


//defining columns outside of the component is fine, is stable
const columns = [
  {
    accessorKey: 'name',
    header: 'Disease Name',
    size: 120,
  },
  {
    accessorKey: 'disease_signs',
    header: 'Disease Signs/Symptoms',
    size: 300,
  },
  {
    accessorKey: 'origin',
    header: 'Disease Origin',
  },
  {
    accessorKey: 'firstEncounter',
    header: 'First Detected',
    size: 220,
  },
  {
    accessorKey: 'reportedInfections',
    header: 'Reported Infections',
    size: 220,
  },
  {
    accessorKey: 'number_of_farmers',
    header: 'Number of Farmers Affected',
    size: 220,
  }
];

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: columns.map((c) => c.header),
};

const csvExporter = new ExportToCsv(csvOptions);

const DiseasesAnalysis= () => {
  const [diseasesData, setDiseasesData] = useState([])
  const [chartData, setChartData] = useState([])
  const [stackedAreaChartData, setStackedAreaChartData] = useState([])

  useEffect(() => {
    pb.collection("diseases").getFullList()
    .then((data) => {
      setDiseasesData(data);
  }).catch((err) => {
    console.log(err);
  });
  }, []);

  useEffect(() => {
    const chartData = diseasesData.map((item) => ({
      name: item.disease_name,
      value: item.reported_infections,
    }));
    const stackedAreaChartData = diseasesData.map((item) => ({
      name: item.origin,
      diseases: item.disease_name,
      infections: item.reported_infections,
      farmers: item.number_of_farmers,
    }));
    setStackedAreaChartData(stackedAreaChartData);
    setChartData(chartData);
  }, [diseasesData]);

  const data = diseasesData.map((item) => ({
    name: item.disease_name,
    disease_signs: item.disease_signs,
    origin: item.origin,
    firstEncounter: item.first_detected,
    reportedInfections: item.reported_infections,
    number_of_farmers: item.number_of_farmers,
  }));

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };


    return (
      <>
            <Card>
            <MDBox pt={3}>
                <MDTypography variant="h4" align="center" gutterBottom>Diseases Records</MDTypography>
            <MaterialReactTable
              columns={columns}
              data={data}
              enableRowSelection
              positionToolbarAlertBanner="bottom"
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                  <Button
                    color="inherit"
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    onClick={handleExportData}
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                  >
                    Export All Data
                  </Button>
                  <Button
                  color="inherit"
                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                    //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() =>
                      handleExportRows(table.getPrePaginationRowModel().rows)
                    }
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                  >
                    Export All Rows
                  </Button>
                  <Button
                  color="inherit"
                    disabled={table.getRowModel().rows.length === 0}
                    //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                    onClick={() => handleExportRows(table.getRowModel().rows)}
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                  >
                    Export Page Rows
                  </Button>
                  <Button
                  color="inherit"
                    disabled={
                      !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                    }
                    //only export selected rows
                    onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                  >
                    Export Selected Rows
                  </Button>
                </Box>
              )}
            />
            </MDBox>
            </Card>
            <Card style={{ marginTop: "4px" }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MDBox pt={3}>
                    <MDTypography variant="h5" align="center" >Diseases Infection Rates</MDTypography>
                    <DiseaseAnalysisPieChart
                        data={chartData}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={6}>
                  <MDBox pt={3}>
                    <MDTypography variant="h5" align="center" gutterBottom>Analysis By Country</MDTypography>
                    <StackedAreaChart
                        data={stackedAreaChartData}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
            </>
    );
  }

export default DiseasesAnalysis;