import React, { useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Label } from 'recharts';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import MDButton from 'components/MDButton';


const PestsAnalysisPieChart = (props) => {
    const {data} = props;
    const [chartRef, setChartRef] = useState(null);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const handleExport = () => {
      const input = chartRef;
      html2canvas(input).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF({
              orientation: 'landscape',
              unit: 'px',
              format: [canvas.width, canvas.height]
          });
          pdf.text('This chart shows pests attack rates over time', 14, 22);
          pdf.addImage(imgData, 'PNG', 0, 32);
          pdf.save('chart.pdf');
      })
  }

return (
  <>
  <MDButton
      style={{marginLeft: '30px'}}
      variant="contained"
      size="small"
      color="primary" 
      onClick={handleExport}>
          Export as PDF
  </MDButton>
  <div ref={(ref) => setChartRef(ref)}>
    <PieChart width={500} height={500}>
    <Pie
      data={data}
      cx={200}
      cy={200}
      innerRadius={60}
      outerRadius={80}
      fill="#8884d8"
      dataKey="value"
      label
      labelLine={true}
    >
      {data.map((entry, index) => (
        <Cell key={index} fill={COLORS[index % COLORS.length]} />
      ))}
      {data.map((entry, index) => (
      <Label
        key={index} 
        position="outside"
        fill={COLORS[index % COLORS.length]}
        style={{ fontSize: 18, fill: '#333' }}
        content={entry.name}
      />
      ))}
    </Pie>
    <Tooltip/>
  </PieChart>
  </div>
</>
)
}

export default PestsAnalysisPieChart;
