import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MapContainer from "./components/map";
import { withAuthenticator } from '@aws-amplify/ui-react'

function AnalyticsMap() {

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox py={3}>
                <MapContainer/>
            </MDBox>
            <Footer/>
        </DashboardLayout>
    )
}

export default withAuthenticator(AnalyticsMap);