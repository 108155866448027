import Dashboard from "layouts/dashboard";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";

// @mui icons
import Icon from "@mui/material/Icon";
import AnalyticsMap from "layouts/Map";
import Clients from "layouts/Clients";
import DiseasesAnalytics from "layouts/PestsAndDiseasesAnalytics/diseases";
import PestsAnalytics from "layouts/PestsAndDiseasesAnalytics/pests";
import ClientsOverview from "layouts/Clients/components/profile";
import WeatherData from "layouts/WeatherData";
import News from "layouts/News";


const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/clients",
    component: <Clients/>,
  },
  {
    type: "collapse",
    name: "View profile",
    key: "view profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/clients/profile/:id",
    component: <ClientsOverview/>,
  },
  {
    type: "collapse",
    name: "Map",
    key: "map",
    icon: <Icon fontSize="small">map</Icon>,
    route: "/map",
    component: <AnalyticsMap/>,
  },
  {
    type: "collapse",
    name: "Pests database",
    key: "pests database",
    icon: <Icon fontSize="small">bug_report</Icon>,
    route: "/pests-database",
    component: <PestsAnalytics/>,
  },
  {
    type: "collapse",
    name: "Diseases database",
    key: "diseases database",
    icon: <Icon fontSize="small">bug_report</Icon>,
    route: "/diseases-database",
    component: <DiseasesAnalytics/>,
  },
  {
    type: "collapse",
    name: "Weather Data",
    key: "weather",
    icon: <Icon fontSize="small">thunderstorm</Icon>,
    route: "/weather",
    component: <WeatherData/>,
  },
  {
    type: "collapse",
    name: "News",
    key: "news",
    icon: <Icon fontSize="small">newspaper</Icon>,
    route: "/news",
    component: <News/>
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    key: "sign-in",
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
];

export default routes;
