import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "layouts/dashboard/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "layouts/Clients/components/profile/data/profileList";
import Header from "layouts/Clients/components/profile/components/Header";
import PlatformSettings from "layouts/Clients/components/profile/components/PlatformSettings";
import avatar from "assets/avatar.png";
import pb from "lib/pocketbase";
import { useParams } from "react-router-dom";
import RegionWeatherData from "./components/regionWeatherData";
import ClientDetectionLogs from "layouts/dashboard/components/LatestLogs";
import { Button, Chip, CircularProgress, Icon, MenuItem, Modal, Paper, TextField } from "@mui/material";
import MDInput from "components/MDInput";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";


const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    backdropFilter: "blur(4px)", // Adjust the blur intensity as needed
    background: "rgba(0, 0, 0, 0.5)", // Adjust the background color and opacity as needed
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    filter: "blur(5px)", // Apply blur when loading is true
    transition: "filter 0.3s ease-in-out",
  },
  loader: {
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 0.9)", // Adjust the loader background color and opacity as needed
    padding: "16px",
    borderRadius: "4px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", // Add a box shadow for the loader
  },
}));


function Overview() {
  const classes = useStyles()
  const [client, setClient] = useState([]);
  const { id } = useParams();
  const { handleSubmit, reset } = useForm();
  const [profilesListData, setProfilesListData] = useState([]);
  const [similarClients, setSimilarClients] = useState([]);
  const [openCompleteProfileModal, setOpenCompleteProfileModal] = useState(false);
  const [editableFields, setEditableFields] = useState({});
  const [chipColor, setChipColor] = useState("warning")
  const [loading, setLoading] = useState(false)
  const [detectionLogs, setDetectionLogs] = useState([]);
  const [logsLoading, setLogsLoading] = useState(false);

  useEffect(() => {
    pb.collection('clients').getOne(id, {
      expand: ['user']
    }).then((client) => {
      setClient(client);
    }).catch((err) => {
      console.log(err);
    });
  }, [id]);


  useEffect(() => {
    pb.collection('clients').getFullList().then((clients) => {
      setProfilesListData(clients);
    }).catch((err) => {
      console.log(err);
    });
  }, [client]);


  useEffect(() => {
    const similarClients = profilesListData.filter((profile) => {
      return profile?.district === client?.district;
    });
    setSimilarClients(similarClients);
  }, [profilesListData, client?.district]);


  const filterSimilarClients = similarClients.slice(0, 5).map((client) => {
      return {
        image: avatar,
        name: client?.full_name,
        phone: `+${client.phone_number}`,
        action: {
          type: "internal",
          route: `/clients/profile/${client?.id}`,
          color: "info",
          label: "view",
        },
      }
  });

  const handleFieldChange = (description, value) => {
    setEditableFields({
      ...editableFields,
      [description]: value
    });
  }

  const accountStatusOptions = ["active", "banned", "waiting list"];
  const maritalStatusOptions = ["single", "married", "divorced", "widowed"]

  const handleAccountStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setEditableFields({
      ...editableFields,
      account_status: selectedStatus,
    });
  };

  const handleMaritalStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setEditableFields({
      ...editableFields,
      marital_status: selectedStatus,
    })
  }

  const soilCompositionData = [
    {
      title: "pH level",
      description: client?.ph_level || "N/A",
    },
    {
      title: "Carbon content",
      description: client?.carbon_content || "N/A",
    },
    {
      title: "Organic matter",
      description: client?.organic_matter || "N/A",
    },
    {
      title: "Soil type",
      description: client?.soil_type || "N/A",
    },
    {
      title: "Other nutrients",
      description: client?.other_nutrients || "N/A",
    }
  ]

  const landInfoData = [
    {
      title: "Year of establishment",
      description: client?.year_of_establishment || "N/A",
    },
    {
      title: "Land tenure",
      description: client?.land_tenure || "N/A",
    },
    {
      title: "Total area allocated for arable production (In hectares)",
      description: client?.total_area_allocated_for_arable_production || "N/A", 
    },
    {
      title: "Total area under production (In hectares)",
      description: client?.total_area_under_production || "N/A",
    },
    {
      title: "Manager's name (if not the same as the owner)",
      description: client?.manager_name || "N/A",
    },
    {
      title: "Manager's phone number",
      description: client?.manager_phone_number || "N/A",
    },
    {
      title: "Manager's qualifications",
      description: client?.manager_qualifications || "N/A",
    },
    {
      title: "Type of draft power",
      description: client?.type_of_draft_power || "N/A",
    }
  ]

  const formFields = [
    {
      title: "Full name",
      description: "name",
      type: "text",
      value: client?.full_name,
      required: true,
      name: "full_name"
    },
    {
      title: "Phone number",
      description: "phone",
      type: "tel",
      value: client?.phone_number,
      required: true,
      name: "phone_number"
    },
    {
      title: "Email address",
      description: "email",
      type: "email",
      value: client?.email,
      name: "email"
    },
    {
      title: "Nationality",
      description: "nationality",
      value: client?.nationality,
      name: "nationality"
    },
    // {
    //   title: "Marital Status",
    //   description: "marital status",
    //   value: client?.marital_status,
    //   name: "marital_status"
    // },
    {
      title: "District",
      description: "district",
      type: "text",
      value: client?.district,
      required: true,
      name: "district"
    },
    {
      title: "Supporting Organization",
      description: "supporting organization",
      type: "text",
      value: client?.supporting_organization,
      required: true,
      name: "supporting_organization"
    },
    {
      title: "Workplace",
      description: "workplace",
      type: "text",
      value: client?.workplace,
      name: "workplace"
    },
    {
      title: "Job title",
      description: "job title",
      type: "text",
      value: client?.job_title,
      name: "job_title"
    },
    {
      title: "Year of establishment",
      description: "year of establishment",
      type: "number",
      value: client?.year_of_establishment,
      required: true,
      name: "year_of_establishment"
    },
    {
      title: "Land tenure(e.g. Communal, freehold, leasehold, stateland etc...)",
      description: "land tenure",
      type: "text",
      value: client?.land_tenure,
      required: true,
      name: "land_tenure"
    },
    {
      title: "Total area allocated for arable production (In hectares)",
      description: "total area allocated for arable production",
      type: "number",
      value: client?.total_area_allocated_for_arable_production,
      required: true,
      name: "total_area_allocated_for_arable_production"
    },
    {
      title: "Total area under production (In hectares)",
      description: "total area under production",
      type: "number",
      value: client?.total_area_under_production,
      required: true,
      name: "total_area_under_production"
    },
    {
      title: "Manager's name (if not the same as the owner)",
      description: "manager's name",
      type: "text",
      value: client?.manager_name,
      name: "manager_name"
    },
    {
      title: "Manager's phone number",
      description: "manager's phone number",
      type: "tel",
      value: client?.manager_phone_number,
      name: "manager_phone_number"
    },
    {
      title: "Manager's qualifications",
      description: "manager's qualifications",
      type: "text",
      value: client?.manager_qualifications,
      name: "manager_qualifications"
    },
    {
      title: "Type of draft power(e.g. machinery, oxen etc...)",
      description: "type of draft power",
      type: "text",
      value: client?.type_of_draft_power,
      name: "type_of_draft_power"
    },
    {
      title: "pH level",
      description: "pH level",
      type: "text",
      value: client?.ph_level,
      name: "ph_level"
    },
    {
      title: "Carbon content",
      description: "carbon content",
      type: "text",
      value: client?.carbon_content,
      name: "carbon_content"
    },
    {
      title: "Organic matter",
      description: "organic matter",
      type: "text",
      value: client?.organic_matter,
      name: "organic_matter"
    },
    {
      title: "Soil type",
      description: "soil type",
      type: "text",
      value: client?.soil_type,
      name: "soil_type"
    },
    {
      title: "Other nutrients",
      description: "other nutrients",
      type: "text",
      value: client?.other_nutrients,
      name: "other_nutrients"
    },
    {
      title: "Account status",
      description: "account status",
      type: "text",
      value: client?.account_status,
      name: "account_status"
    }
  ]

  async function updateClient() {
    setLoading(true)
    try {
      const updatedClient = await pb.collection('clients').update(id, editableFields);
    setClient(updatedClient);
    setOpenCompleteProfileModal(false);
    setLoading(false)
    toast.success('Profile updated successfully', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
    });
    } catch (error) {
      toast.error('Failed to update profile', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
      });
      setLoading(false)
    }
    
  }

  useEffect(() => {
    if (client?.account_status === "active") {
      setChipColor("success");
    } else if (client?.account_status === "banned") {
      setChipColor("error")
    } else if (client?.account_status === "waiting list") {
      setChipColor("warning")
    }
  }, [client])

  useEffect(() => {
    const fetchDetectionLogs = async () => {
      try {
        setLogsLoading(true);
        const res = await axios.get(`${process.env.REACT_APP_MONGO_EC2_SEVER}/api/messages`);
        const filteredLogs = res?.data.filter(log => log.phone == client?.phone_number);
        setDetectionLogs(filteredLogs);
        setLogsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    fetchDetectionLogs(); 
  }, [client?.phone_number]);

  return (
    <>
        <ToastContainer />
    <Modal
        open={openCompleteProfileModal}
        onClose={() => setOpenCompleteProfileModal(false)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "80%",
          width: "80%",
          margin: "auto",
          overflow: "scroll"
        }}
        className={loading && classes.modalWrapper}
      >
        <Paper elevation={3} className={classes.loader}>
          <MDBox p={3} component="form" role="form" onSubmit={handleSubmit(updateClient)}>
            <MDTypography variant="h2" fontWeight="medium" style={{ marginBottom: "20px" }}>
              Complete Profile - <span style={{ marginBottom: "20px", fontWeight: "normal" }}>{client?.full_name}</span> <Chip label={client?.account_status} color={chipColor}/>
            </MDTypography>
            {loading ? (
              <CircularProgress/>
            ) : (
              <Grid container spacing={2}>
            {formFields.map((data, index) => (
              <Grid item xs={6} key={index}>
                <MDBox mb={2}>
                  {/* {data.name === "marital_status" && (
                    <MDInput
                    select
                    type={data.type}
                    label={data.title}
                    value={editableFields.account_status || client.account_status}
                    helperText="Please select marital status"
                    onChange={handleMaritalStatusChange}
                    fullWidth
                  >
                    {maritalStatusOptions.map((option, index) => (
                      <MenuItem key={index} value={option} >
                        {option}
                      </MenuItem>
                    ))}
                  </MDInput>
                  )} */}
                {data.name === "account_status" ? (
                  <MDInput
                    select
                    type={data.type}
                    label={data.title}
                    value={editableFields.account_status || client.account_status}
                    helperText="Please select account status"
                    onChange={handleAccountStatusChange}
                    fullWidth
                  >
                    {accountStatusOptions.map((option, index) => (
                      <MenuItem key={index} value={option} >
                        {option}
                      </MenuItem>
                    ))}
                  </MDInput>
                ) : (
                  <MDInput
                    type={data.type}
                    label={data.title}
                    value={editableFields[data.name] || data.value}
                    required={data.required}
                    fullWidth
                    onChange={(e) => handleFieldChange(data.name, e.target.value)}
                  />
                )}
                  </MDBox>
              </Grid>
            ))}
            </Grid>
            )}
            
            <MDBox
                display="flex"
                justifyContent="flex-end"
                mt={2} 
              >
                <Button
                  size="large" 
                  variant="contained" 
                  color="primary"
                  startIcon={<Icon>draw</Icon>}
                  style={{ color: "text" }}
                  type="submit"
                  >
                  Update
                </Button>
              </MDBox>
          </MDBox>
        </Paper>
      </Modal>
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header
        fullName={client.full_name}
        phone={`+${client.phone_number}`}
        accountStatus={client.account_status}
        completeProfile={() => setOpenCompleteProfileModal(true)}
      >
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                description=""
                info={{
                  fullName: client?.full_name,
                  mobile: `+${client?.phone_number}`,
                  email: client?.email,
                  location: client?.district,
                  gender: client?.gender,
                  workplace: client?.workplace,
                }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} xl={4}>
              <ProfilesList 
                title="Clients in the same region"
                phoneNumber={`+${client.phone_number}`}
                profiles={filterSimilarClients}
                total={similarClients.length} 
                shadow={false} />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox p={2} px={2} lineHeight={1.25}>
          <Grid container spacing={4}>
            <Grid item xs={8}>
              <MDTypography variant="h6" fontWeight="medium">Farm Info</MDTypography>
              {landInfoData.map((data, index) => (
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={3} mt={2}>
                    {data.title}
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={3} mt={2}>
                    {data.description}
                  </MDBox>
                </Grid>
              </Grid>
              ))}
            </Grid>
            
            <Grid item xs={4}>
              <MDTypography variant="h6" fontWeight="medium">
                Detection Logs
              </MDTypography>
              <MDBox mt={2}>
                <ClientDetectionLogs
                  detectionLogs={detectionLogs}
                  loading={logsLoading}
                />
              </MDBox>
            </Grid>
            
            </Grid>
        </MDBox>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <Grid container spacing={4}>
            <Grid item xs={8}>
              <MDTypography variant="h6" fontWeight="medium">
                Weather data in the region
              </MDTypography>
              <MDBox mt={2}>
                <RegionWeatherData
                  city={client?.district}
                />
              </MDBox>
            </Grid>
            <Grid item xs={4}>
                <MDTypography variant="h6" fontWeight="medium">
                  Soil composition
                </MDTypography>
                {soilCompositionData.map((data, index) => (
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3} mt={2}>
                      {data.title}
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={3} mt={2}>
                      {data.description}
                    </MDBox>
                  </Grid>
                </Grid>
                ))}
              </Grid>
            
          </Grid>
        </MDBox>

        
      </Header>
      <Footer />
    </DashboardLayout>
    </>
  );
}

export default Overview;
