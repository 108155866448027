import React, { useEffect, useState } from 'react';
import MDBox from 'components/MDBox';
import { Card, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton } from '@mui/material';
import MDTypography from 'components/MDTypography';

import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import EditIcon from '@mui/icons-material/Edit';
import pb from 'lib/pocketbase';
import PestsAnalysisPieChart from './charts/pestsAnalysisPieChart';
import StackedAreaChart from './charts/analysisByCountry';
import TrendChart from './charts/trends';


//defining columns outside of the component is fine, is stable
const columns = [
  {
    accessorKey: 'name',
    header: 'Pest name',
    size: 120,
  },
  {
    accessorKey: 'pest_signs',
    header: 'Pest Signs/Symptoms',
    size: 300,
  },
  {
    accessorKey: 'origin',
    header: 'Origin',
  },
  {
    accessorKey: 'firstEncounter',
    header: 'First Detected',
    size: 220,
  },
  {
    accessorKey: 'reportedAttacks',
    header: 'Reported Attacks',
    size: 220,
  },
  {
    accessorKey: 'number_of_farmers',
    header: 'Number of Farmers Affected',
    size: 220,
  },
  {
    accessorKey: 'action',
    header: '',
  }
];

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: columns.map((c) => c.header),
};

const csvExporter = new ExportToCsv(csvOptions);

const PestsAnalysis= () => {
  const [pestsData, setPestsData] = useState([])
  const [chartData, setChartData] = useState([])
  const [stackedAreaChartData, setStackedAreaChartData] = useState([])
  const [open, setOpen] = useState(false);
  const [selectedPest, setSelectedPest] = useState(null);
  const [trendData, setTrendData] = useState([]);


  useEffect(() => {
    pb.collection("pests").getFullList()
    .then((data) => {
      setPestsData(data);
  }).catch((err) => {
    console.log(err);
  });
  }, []);

  const handleOpen = (pest) => {
    setSelectedPest(pest);
    setOpen(true);
  }


  useEffect(() => {
    // Calculate trends in reported attacks over time
    const calculateTrends = () => {
      const trends = [];
      for (let i = 1; i < pestsData.length; i++) {
        const prevAttacks = pestsData[i - 1].reportedAttacks;
        const currentAttacks = pestsData[i].reportedAttacks;
        const trend = currentAttacks - prevAttacks;
        trends.push({ timestamp: pestsData[i].firstEncounter, trend });
      }
      setTrendData(trends);
    };

    calculateTrends();
  }, [pestsData]);

  useEffect(() => {
    const chartData = pestsData.map((item) => ({
      name: item.pest_name,
      value: item.reported_attacks,
    }));
    const stackedAreaChartData = pestsData.map((item) => ({
      name: item.origin,
      pests: item.pest_name,
      attacks: item.reported_attacks,
      farmers: item.number_of_farmers,
    }));
    setStackedAreaChartData(stackedAreaChartData);
    setChartData(chartData);
  }, [pestsData]);


  const data = pestsData.map((item) => ({
    name: item.pest_name,
    type: item.type,
    origin: item.origin,
    pest_signs: item.pest_signs,
    firstEncounter: new Date(item.first_detected).toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
    reportedAttacks: item.reported_attacks,
    number_of_farmers: item.number_of_farmers,
    action: (
      <MDBox
      sx={{
        display: "flex",
        alignImems:"center",
        gap: "0.5rem",
      }}
    >
      <MDBox
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <Button
          color="inherit"
          variant="contained"
          onClick={() => handleOpen(item)}
        >
          View
        </Button>
      </MDBox>
    </MDBox>
    )
  }));
  

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };


    return (
        <>
        <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle>
                      Pest details
                      <IconButton
                        aria-label="edit"
                        onClick={() => setOpen(false)}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <EditIcon/>
                      </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Detailed information about the selected pest
                        </DialogContentText>
                        <Grid container>
                          <Grid item xs={8}>
                          <MDTypography variant="p" >Pest name: </MDTypography>
                          </Grid>
                          <Grid item xs={4}>
                          <MDTypography variant="button" fontWeight="medium" color="text">{selectedPest?.pest_name}</MDTypography>
                          </Grid>
                          <Grid item xs={8}>
                          <MDTypography variant="p" >Type: </MDTypography>
                          </Grid>
                          <Grid item xs={4}>
                          <MDTypography variant="button" fontWeight="medium" color="text">{selectedPest?.type}</MDTypography>
                          </Grid>
                          <Grid item xs={8}>
                          <MDTypography variant="p" >Origin: </MDTypography>
                          </Grid>
                          <Grid item xs={4}>
                          <MDTypography variant="button" fontWeight="medium" color="text">{selectedPest?.origin}</MDTypography>
                          </Grid>
                          <Grid item xs={8}>
                          <MDTypography variant="p" >Signs: </MDTypography>
                          </Grid>
                          <Grid item xs={4}>
                          <MDTypography variant="button" fontWeight="medium" color="text">{selectedPest?.pest_signs}</MDTypography>
                          </Grid>
                          <Grid item xs={8}>
                          <MDTypography variant="p" >Prevention methods: </MDTypography>
                          </Grid>
                          <Grid item xs={4}>
                          <MDTypography variant="button" fontWeight="medium" color="text">{selectedPest?.prevention}</MDTypography>
                          </Grid>
                          <Grid item xs={8}>
                          <MDTypography variant="p" >Reported attacks: </MDTypography>
                          </Grid>
                          <Grid item xs={4}>
                          <MDTypography variant="button" fontWeight="medium" color="text">{selectedPest?.reported_attacks}</MDTypography>
                          </Grid>
                          <Grid item xs={8}>
                          <MDTypography variant="p" >Approximate Number of farmers affected: </MDTypography>
                          </Grid>
                          <Grid item xs={4}>
                          <MDTypography variant="button" fontWeight="medium" color="text">{selectedPest?.number_of_farmers}</MDTypography>
                          </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            <Card>
              <MDBox pt={3}>
                  <MDTypography variant="h4" align="center" gutterBottom>Pests Records</MDTypography>
                <MaterialReactTable
                  columns={columns}
                  data={data}
                  enableRowSelection
                  positionToolbarAlertBanner="bottom"
                  renderTopToolbarCustomActions={({ table }) => (
                    <Box
                      sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                      <Button
                        color="inherit"
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        onClick={handleExportData}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                      >
                        Export All Data
                      </Button>
                      <Button
                      color="inherit"
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        //export all rows, including from the next page, (still respects filtering and sorting)
                        onClick={() =>
                          handleExportRows(table.getPrePaginationRowModel().rows)
                        }
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                      >
                        Export All Rows
                      </Button>
                      <Button
                      color="inherit"
                        disabled={table.getRowModel().rows.length === 0}
                        //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                        onClick={() => handleExportRows(table.getRowModel().rows)}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                      >
                        Export Page Rows
                      </Button>
                      <Button
                      color="inherit"
                        disabled={
                          !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        //only export selected rows
                        onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                      >
                        Export Selected Rows
                      </Button>
                    </Box>
                  )}
                />
              </MDBox>
            </Card>

            <Card style={{ marginTop: "4px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MDBox pt={3}>
                  <MDTypography variant="h5" align="center" >Pests Attack Rates</MDTypography>
                  <PestsAnalysisPieChart
                      data={chartData}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6}>
              <MDBox pt={3}>
                  <MDTypography variant="h5" align="center" gutterBottom>Recent Trends</MDTypography>
                  <TrendChart
                      data={trendData}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container style={{ margin: "auto" }}>
                <MDBox pt={3}>
                  <MDTypography variant="h5" align="center" gutterBottom>Analysis By Country</MDTypography>
                  <StackedAreaChart
                      data={stackedAreaChartData}
                  />
                </MDBox>
            </Grid>
            </Card>
        </>
    );
  }

export default PestsAnalysis;