import React, { useEffect, useState } from "react";
import { CircularProgress, Divider } from "@mui/material";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

function LogsOverview({ detectionLogs, loading }) {

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          WhatsApp Logs
        </MDTypography>
      </MDBox>
      {loading ? 
        <MDBox p={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </MDBox>
        : (
       <MDBox p={2} sx={{ overflowY: 'auto', maxHeight: '400px' }}>
        {detectionLogs?.map((log, index) => (
          <TimelineItem
            key={index}
            color="warning"
            icon="phone_iphone"
            title={log?.menu}
            dateTime={`+${log?.phone}`}
            description={log?.message}
          />
        ))}
     </MDBox>
  )}
    </Card>
  );
}

export default LogsOverview;
