import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useForm } from "react-hook-form";
import pb from "lib/pocketbase"; 
import { useNavigate } from "react-router-dom";
// Images
import bgImage from "assets/images/1.jpg";
import Alert from "@mui/material/Alert";
import { CircularProgress } from "@mui/material";

function Basic() {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const isLoggedIn = pb?.authStore?.isValid;

  async function login(data) {
    setIsLoading(true);
    try {
      await pb.admins.authWithPassword(data?.email, data?.password);
    } catch (e) {
      setError(e.message);
    } 
    setIsLoading(false);
  }

  if (isLoggedIn) {
    navigate("/dashboard");
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit(login)}>
            <MDBox mb={2}>
              <MDInput type="email" label="Email" fullWidth {...register("email")}/>
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" fullWidth {...register("password")}/>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={isLoading}>
              {isLoading ? <CircularProgress color="inherit"/> : "Sign in"}
              </MDButton>
            </MDBox>
          {error && <Alert severity="error">{error}</Alert>}

          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
