import React, { useState } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import MDButton from 'components/MDButton';

const TrendChart = (props) => {
  const { data } = props;
  const [chartRef, setChartRef] = useState(null);

  const handleExport = () => {
    const input = chartRef;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [canvas.width, canvas.height]
      });
      pdf.text('Recent Trends in Pest Attacks', 14, 22);
      pdf.addImage(imgData, 'PNG', 0, 32);
      pdf.save('trend_chart.pdf');
    });
  };

  return (
    <>
      <MDButton
        style={{ marginLeft: '30px' }}
        variant="contained"
        size="small"
        color="primary"
        onClick={handleExport}
      >
        Export as PDF
      </MDButton>
      <div ref={(ref) => setChartRef(ref)}>
        <LineChart
          width={550}
          height={400}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="timestamp" type="category" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="attacks" stroke="#8884d8" name="" />
        </LineChart>
      </div>
    </>
  );
};

export default TrendChart;
