import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "layouts/dashboard/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "layouts/dashboard/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "layouts/dashboard/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import PestsAnalysisByCountry from "layouts/dashboard/components/pestsAnalysisByCountry";
import DetectionLogs from "layouts/dashboard/components/LatestLogs";
import MDTypography from "components/MDTypography";
import { withAuthenticator } from '@aws-amplify/ui-react'
import pb from "lib/pocketbase";
import axios from "axios";

function Dashboard({ user, signOut }) {
  const { diseases, pests } = reportsLineChartData;
  const [usersCount, setUsersCount] = useState(0);
  const [scoutingsCount, setScoutingsCount] = useState(0);
  const [pestsAnalysis, setPestsAnalysis] = useState([]);
  const [noOfHectaresScouted, setNoOfHectaresScouted] = useState(0);
  const [stackedBarChartData, setStackedBarChartData] = useState([]);
  const [detectionLogs, setDetectionLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      pb.collection("clients").getFullList().then((users) => {
        setUsersCount(users.length);
      });
      pb.collection("pests").getFullList().then((pests) => {
        setPestsAnalysis(pests);
      });
      pb.collection("scoutings").getFullList().then((scoutings) => {
        setScoutingsCount(scoutings.length);

          let totalHectares = 0;
          scoutings.forEach((scouting) => {
            const approximateFarmSize = scouting?.approximate_farm_size || 0;
            totalHectares += parseInt(approximateFarmSize, 10);
          });
          setNoOfHectaresScouted(totalHectares);
          
      })

    } catch (error) {
      console.log(error);
    }
  }, []);


  useEffect(() => {
    const data = pestsAnalysis.map((pest) => {
      return {
        name: pest.origin,
        pests: pest.pest_name,
        attacks: pest.reported_attacks,
        farmers: pest.number_of_farmers,
      };
    });
    setStackedBarChartData(data);
  }, [pestsAnalysis]);

  useEffect(() => {
    const fetchDetectionLogs = async () => {
      try {
      setLoading(true);
        const res = await axios.get(`${process.env.REACT_APP_MONGO_EC2_SEVER}/api/messages`);
        setDetectionLogs(res?.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    fetchDetectionLogs(); 
  }, []);


  return (
    <DashboardLayout>
      <DashboardNavbar onLogout={signOut}/>
      <MDBox pt={1}>
        Welcome 
        <MDTypography variant="h5" align="left" gutterBottom>
          <span style={{ fontStyle: "bold" }}>{user.attributes.email}</span>       
        </MDTypography>
      </MDBox>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="travel_explore"
                title="Field Scoutings"
                count={scoutingsCount}
                percentage={{
                  color: "success",
                  amount: "+100%",
                  label: "than lask month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="people"
                title="Total Clients"
                count={usersCount}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="agriculture"
                title="Hectares Scouted"
                count={noOfHectaresScouted}
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Followers"
                count="+91"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Disease infection rates"
                  description={
                    <>
                      (<strong>+15%</strong>) increase.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={diseases}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Pests infection rates"
                  date="just updated"
                  chart={pests}
                  description={
                    <>
                      (<strong>+15%</strong>) increase.
                    </>
                  }
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <PestsAnalysisByCountry 
                data={stackedBarChartData}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetectionLogs
                detectionLogs={detectionLogs}
                loading={loading} 
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default withAuthenticator(Dashboard);
