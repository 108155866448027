import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import "./map.css"
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as turf from '@turf/turf';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`

export default function MapContainer() {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const geocoderContainerRef = useRef(null);
  const [lng, setLng] = useState(25.9231);
  const [lat, setLat] = useState(-24.6282);
  const [zoom, setZoom] = useState(9);

  const [searchTerm, setSearchTerm] = useState('');
const [farmClass, setFarmClass] = useState('');

const handleSearchChange = (event) => {
  setSearchTerm(event.target.value);
  // Add logic to handle search
};

const handleClassChange = (event) => {
  setFarmClass(event.target.value);
  // Add logic to filter farms based on class
};


  

  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    mapboxgl: mapboxgl
});

const farms = [
  {
    id: 'farm1',
    name: 'Tuli Block Farm',
    center: [29.1298, -22.1836],
    corners: [
      [29.1253, -22.1881], // Top Left
      [29.1343, -22.1881], // Top Right
      [29.1343, -22.1791], // Bottom Right
      [29.1253, -22.1791], // Bottom Left
      [29.1253, -22.1881], // Closing the loop (Top Left)
    ],
    description: 'Tuli Block Farm is known for its diverse wildlife and beautiful landscapes.'
  },
  {
    id: 'farm2',
    name: 'Okavango Delta Farm',
    center: [22.6560, -19.3832],
    corners: [
      [22.6515, -19.3877],
      [22.6605, -19.3877],
      [22.6605, -19.3787],
      [22.6515, -19.3787],
      [22.6515, -19.3877],
    ],
    description: 'Located in the heart of the Delta, offering scenic views of the wetlands.'
  },
  {
    id: 'farm3',
    name: 'Chobe National Park Farm',
    center: [24.5179, -18.7669],
    corners: [
      [24.5134, -18.7714],
      [24.5224, -18.7714],
      [24.5224, -18.7624],
      [24.5134, -18.7624],
      [24.5134, -18.7714],
    ],
    description: 'Adjacent to Chobe National Park, this farm is famous for its sustainable practices.'
  },
  {
    id: 'farm4',
    name: 'Gaborone Farm',
    center: [25.9231, -24.6282],
    corners: [
      [25.9186, -24.6327],
      [25.9276, -24.6327],
      [25.9276, -24.6237],
      [25.9186, -24.6237],
      [25.9186, -24.6327],
    ],
    description: 'Located near the capital city, Gaborone Farm specializes in organic produce.'
  },
];

const coordinatesGeocoder = function (query) {
  // Match anything which looks like
  // decimal degrees coordinate pair.
  const matches = query.match(
  /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
  );
  if (!matches) {
  return null;
  }
   
  function coordinateFeature(lng, lat) {
  return {
  center: [lng, lat],
  geometry: {
  type: 'Point',
  coordinates: [lng, lat]
  },
  place_name: 'Lat: ' + lat + ' Lng: ' + lng,
  place_type: ['coordinate'],
  properties: {},
  type: 'Feature'
  };
  }
   
  const coord1 = Number(matches[1]);
  const coord2 = Number(matches[2]);
  const geocodes = [];
   
  if (coord1 < -90 || coord1 > 90) {
  // must be lng, lat
  geocodes.push(coordinateFeature(coord1, coord2));
  }
   
  if (coord2 < -90 || coord2 > 90) {
  // must be lat, lng
  geocodes.push(coordinateFeature(coord2, coord1));
  }
   
  if (geocodes.length === 0) {
  // else could be either lng, lat or lat, lng
  geocodes.push(coordinateFeature(coord1, coord2));
  geocodes.push(coordinateFeature(coord2, coord1));
  }
   
  return geocodes;
  };



useEffect(() => {
  if (mapRef.current) return;
  const map = new mapboxgl.Map({
    container: mapContainerRef.current,
    style: 'mapbox://styles/mapbox/satellite-streets-v12',
    center: [lng, lat],
    zoom: zoom,
  });

  mapRef.current = map;

  map.on('load', () => {
    farms.forEach(farm => {
      // Add a marker for each farm
      const polygon = turf.polygon([farm.corners]);
        // Calculate the area in square meters
        const area = turf.area(polygon);

        // Format the area to a more readable form, e.g., hectares or square kilometers
        const areaInHectares = area / 10000; // Convert to hectares

        // Add a marker for each farm
        const el = document.createElement('div');
        el.className = 'marker';
        new mapboxgl.Marker(el)
          .setLngLat(farm.center)
          .setPopup(new mapboxgl.Popup().setHTML(
            `<strong>${farm.name}</strong><p>Area: ${areaInHectares.toFixed(2)} hectares</p>`
          ))
          .addTo(map);

      // Draw a polygon for each farm
      const farmPolygon = {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'Polygon',
            'coordinates': [farm.corners]
          }
        }
      };

      map.addSource(`farm-${farm.id}`, farmPolygon);

      map.addLayer({
        'id': `farm-${farm.id}`,
        'type': 'line',
        'source': `farm-${farm.id}`,
        'layout': {},
        'paint': {
          'line-color': '#000',
          'line-width': 3
          }
      });
    });
  });

  // Add the Geocoder and Navigation controls to the map
  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    localGeocoder: coordinatesGeocoder,
    mapboxgl: mapboxgl,
    marker: false,
  });

  map.addControl(geocoder);
  map.addControl(new mapboxgl.NavigationControl());


  return () => map.remove();
}, []);

useEffect(() => {
  if (!mapRef.current) return;
  mapRef.current.on('move', () => {
    setLng(mapRef.current.getCenter().lng.toFixed(4));
    setLat(mapRef.current.getCenter().lat.toFixed(4));
    setZoom(mapRef.current.getZoom().toFixed(2));
  });
}, []);

return (
  <div>
    <div className="sidebar">
      Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
    </div>
    <div className="filter-section">
    <TextField
      label="Search Farm"
      variant="outlined"
      value={searchTerm}
      onChange={handleSearchChange}
    />
    <FormControl variant="outlined">
      <InputLabel id="farm-class-label">Farm Class</InputLabel>
      <Select
        labelId="farm-class-label"
        value={farmClass}
        onChange={handleClassChange}
        label="Farm Class"
      >
        <MenuItem value=""><em>None</em></MenuItem>
        <MenuItem value="A">Class A</MenuItem>
        <MenuItem value="B">Class B</MenuItem>
        <MenuItem value="C">Class C</MenuItem>
      </Select>
    </FormControl>
    <Button variant="contained" >Enable Directions</Button>
    <Button variant="contained" >6 Months Analytics</Button>
    <Button variant="contained" >3 Months Analytics</Button>
    <Button variant="contained" >14 Days Analytics</Button>
    <Button variant="contained" >Compare Farms</Button>
  </div>
  <div ref={mapContainerRef} className="map-container" />
  </div>
);
}