import React, { useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import MDButton from 'components/MDButton';

const StackedAreaChart = (props) => {
    const [chartRef, setChartRef] = useState(null);
    const {data} = props;

    const handleExport = () => {
        const input = chartRef;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'landscape',
                unit: 'px',
                format: [canvas.width, canvas.height]
            });
            pdf.text('This chart shows the disease analysis by country over time', 14, 22);
            pdf.addImage(imgData, 'PNG', 0, 32);
            pdf.save('chart.pdf');
        })
    }

  return (
    <>
    <MDButton
        style={{marginLeft: '30px'}}
        variant="contained"
        size="small"
        color="primary" 
        onClick={handleExport}>
            Export as PDF
    </MDButton>
    <div ref={(ref) => setChartRef(ref)}>
    <AreaChart
      width={500}
      height={400}
      data={data}
      stackOffset="expand"
      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Area type="monotone" dataKey="diseases" stackId="1" stroke="#8884d8" fill="#8884d8" />
      <Area type="monotone" dataKey="infections" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
        <Area type="monotone" dataKey="farmers" stackId="1" stroke="#ffc658" fill="#ffc658" />
    </AreaChart>
    </div>
    </>
  );
};

export default StackedAreaChart;
