import { Button, Card, CardActions, CardContent, Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useEffect, useState } from 'react';
import ReadMore from './components/readMore';

const News = () => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setLoading(true);
        try {
            fetch(`https://newsapi.org/v2/everything?q=agriculture&sortBy=publishedAt&language=en&apiKey=3fa2c2f69ee040aabfd78e0284683cb1`).then(
            response => response.json()
        ).then(
            data => setNews(data)
        )
        setLoading(false);
        } catch (error) {
            console.log(error.message);
        }
        
    }, []);



    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Typography>Browse through the latest agriculture news to stay updated</Typography>
            
            {loading && <Typography>Loading...</Typography>}

            {/* <ReadMore
            title={news?.articles?.title}
                description={news?.articles?.description}
                open={open}
                close={() => console.log('close')}
            /> */}
                
            <MDBox py={3}>
                {news?.articles?.map((article, index) => (
                <Card sx={{ minWidth: 275, mb: 3 }} variant="outlined" key={index}>
                    <CardContent>
                    <Typography variant='h5'>{article.title}</Typography>
                    <Typography sx={{ fontSize: 14 }} color="inherit">{article.author}</Typography>
                    <Typography variant='body1'>{article.description}</Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small">Read more...</Button>
                    </CardActions>
                </Card>
                ))}
            </MDBox>
        </DashboardLayout>
    );
}

export default News;