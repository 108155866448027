import React from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button, Chip } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here

import pb from 'lib/pocketbase';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useNavigate } from 'react-router-dom';

//defining columns outside of the component is fine, is stable
const columns = [
  {
    accessorKey: 'name',
    header: 'Full Name',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'phone',
    header: 'Phone Number',
  },
  {
    accessorKey: 'district',
    header: 'District',
  },
  {
    accessorKey: 'account_status',
    header: 'Account Status',
  },
  {
    accessorKey: 'joined_date',
    header: 'Date Onboarded',
  },
  {
    accessorKey: 'action',
    header: '',
  }
];

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: columns.map((c) => c.header),
};

const csvExporter = new ExportToCsv(csvOptions);

const AllUsers = () => {
  const navigate = useNavigate();
  const [clients, setClients] = React.useState([]);

  React.useEffect(() => {
    pb.collection("clients").getFullList()
    .then((data) => {
      setClients(data);
  }).catch((err) => {
    console.log(err);
  });
  }, []);

  const data = clients.map((user) => {
    let chipColor = "warning"; // Default to orange
  
    if (user?.account_status === "active") {
      chipColor = "success";
    } else if (user?.account_status === "banned") {
      chipColor = "error";
    } else if (user?.account_status === "waiting list") {
      chipColor = "warning";
    }
  
    return {
      name: user.full_name,
      email: user.email,
      phone: user.phone_number,
      district: user.district,
      account_status: (
        <Chip
          label={user?.account_status}
          color={chipColor}
        />
      ),
      joined_date: new Date(user.created).toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }),
      action: (
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <MDBox
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Button
              color="inherit"
              variant="contained"
              onClick={() => {
                navigate(`/clients/profile/${user.id}`);
              }}
            >
              View
            </Button>
          </MDBox>
        </MDBox>
      ),
    };
  });
  

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  return (
    <MDBox pt={3}>
    <MaterialReactTable
      columns={columns}
      data={data}
      enableRowSelection
      positionToolbarAlertBanner="bottom"
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
        >
          <Button
            color="inherit"
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={handleExportData}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export All Data
          </Button>
          <Button
          color="inherit"
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            //export all rows, including from the next page, (still respects filtering and sorting)
            onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export All Rows
          </Button>
          <Button
          color="inherit"
            disabled={table.getRowModel().rows.length === 0}
            //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
            onClick={() => handleExportRows(table.getRowModel().rows)}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export Page Rows
          </Button>
          <Button
          color='inherit'
            disabled={
              !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
            }
            //only export selected rows
            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export Selected Rows
          </Button>
        </Box>
      )}
    />
    </MDBox>
  );
};

export default AllUsers;
