import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup, Rectangle } from 'react-leaflet';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import { Alert } from '@mui/material';
import { useForm } from 'react-hook-form';
import MDButton from 'components/MDButton';
import districts from './districts.json';
import { GeoJSON } from 'react-leaflet';



const WeatherData = () => {
    const [weatherData, setWeatherData] = useState(null);
    const [city, setCity] = useState("Gaborone");
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [apiError, setAPIError] = useState(null);
    const [coordinates, setCoordinates] = useState({ lat: -24.6545, lng: 25.9086 });

    const API_KEY = `${process.env.REACT_APP_OPEN_WEATHER_MAP_API_KEY}`;
    const API_URL = `https://api.openweathermap.org/data/2.5/weather?q=${city}&units=imperial&appid=${API_KEY}`;


    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${API_URL}&q=${city}`);
          setWeatherData(result.data);
          setCoordinates({ lat: result.data.coord.lat, lng: result.data.coord.lon });
            } catch (error) {
                setAPIError(error);
                console.log(error.message);
            }
          
        };
        fetchData();
      }, [city]);


      const rectangle = [
        [coordinates.lat - 0.3, coordinates.lng + 0.3],
        [coordinates.lat + 0.3, coordinates.lng - 0.3],
      ]


      const pathOptions = { color: 'purple' }
    

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox py={3}>
            <div>
                <MDBox pt={3} mb={3}>
                    <MDTypography variant="h5" align="left" gutterBottom>
                        Use the input field below to search for weather data of any city
                    </MDTypography>
                </MDBox>

                <MDBox mb={2} display="flex" flexDirection="row" alignitems="center">
                <MDBox mr={2}>
                    <MDInput 
                        type="text"
                        label="Search for a city"
                        variant="outlined"
                        {...register("city", { required: true })}
                    />
                </MDBox>
                <MDBox mb={1}>
                    <MDButton 
                    variant="contained"
                    color="info"
                    onClick={handleSubmit((data) => {
                        setCity(data.city);
                    })}
                >
                    search
                </MDButton>
                </MDBox>
                </MDBox>
                <MDBox mb={2}>
                {apiError ? (
                    <Alert severity="error">City not found, kindly check the name and search again</Alert>
                ) : ""}</MDBox>
               
                {weatherData ? (
                    <MapContainer style={{ height: "500px", width: "100%" }} center={[coordinates.lat, coordinates.lng]} zoom={6} scrollWheelZoom={false}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Rectangle bounds={rectangle} pathOptions={pathOptions}/>
                    {/* <GeoJSON data={districts}/> */}
                    <Marker position={[coordinates.lat, coordinates.lng]}>
                        <Popup>
                            <h3>{weatherData?.name}, {weatherData?.sys?.country}</h3>
                            <p>Temperature: {(weatherData?.main.temp - 32) * (5/9)} °C</p>
                            <p>High: {(weatherData?.main?.temp_max -32) * (5/9)} °C</p>
                            <p>Low: {(weatherData?.main?.temp_min -32) * (5/9)} °C</p>
                            <p>Humidity: {weatherData?.main.humidity}%</p>
                            <p>Description: {weatherData.weather[0].description}</p>
                        </Popup>
                    </Marker>
                    </MapContainer>
                ) : (
                    <p>Loading weather data...</p>
                )}
                </div>
            </MDBox>
        </DashboardLayout>
    );
}

export default WeatherData;